<template>
  <div>
    <h2>Facturen</h2>

    <table v-if="invoicesResult && invoicesResult.invoices.length">
      <tr v-for="invoice in invoicesResult.invoices" :key="invoice.id">
        <td>#{{ invoice.id }}</td>
        <td>{{ invoice.invoiceDate }}</td>
        <td>{{ invoice.group.name }}</td>
        <td>{{ invoice.description }}</td>
        <td><a :href="invoice.downloadUrl">Download</a></td>
      </tr>
    </table>

    <p v-else>
      Er zijn nog geen facturen beschikbaar.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useQuery } from 'villus';

export default defineComponent({
  setup() {
    const AllInvoices = `
      query {
        invoices {
          id
          description
          group {
            name
          }
          invoiceDate
          downloadUrl
        }
      }`;

    const { data: invoicesResult } = useQuery({ query: AllInvoices, cachePolicy: 'network-only' });

    return { invoicesResult };
  },
});
</script>
