
import { defineComponent } from 'vue';
import { useQuery } from 'villus';

export default defineComponent({
  setup() {
    const AllInvoices = `
      query {
        invoices {
          id
          description
          group {
            name
          }
          invoiceDate
          downloadUrl
        }
      }`;

    const { data: invoicesResult } = useQuery({ query: AllInvoices, cachePolicy: 'network-only' });

    return { invoicesResult };
  },
});
